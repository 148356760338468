.slider {
  margin: 0 20px;
  overflow: "hidden";
  padding: 2rem 0;
}

.slider .card_slide {
  width: 100%;
  height: 380px;
  border-radius: 10px;
}

.react-multi-carousel-list {
  padding: 0rem 0 2rem 0;
}
/* .react-multiple-carousel__arrow--left {
 
} */

.custom-dot-list-style button {
  border: none;
  background: #f0f0f0;
}
.react-multi-carousel-dot.react-multi-carousel-dot--active button {
  background: #d16500 !important;
}
