.bannerComponent {
    font-family: sans-serif;
    text-align: center;
    /* margin-top: 3.5rem; */
  }
  
  .bannerComponent img {
    /* max-width: 150px; */
    height: 7rem;
    padding: 0 15px;
    /* border-right: .5px dotted grey; */
    object-fit: cover;
  }
  
  .bannerComponent img:last-of-type {
    padding-left: 0;
  }
  
  .banner-inner {
    position: relative;
    width: 100%;
    overflow: hidden;
    height: 7rem;
  }
  
  .banner-wrapper {
    position: absolute;
    display: flex;
  }
  
  .banner-inner section {
    display: flex;
    animation: swipe var(--speed) linear infinite backwards;
  }
  
  @keyframes swipe {
    0% {
      transform: translate(0);
    }
  
    100% {
      transform: translate(-100%);
    }
  }