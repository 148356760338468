.navpadding{
    padding: 0px 6rem;
}

.dropdown-menu{
    background-color: rgba(0, 0, 0, 0.719) !important;
    width: 50px !important;
    
}
.dropdown-item{
    color: #fff !important;
    font-weight: bold !important;
}
.dropdown-item:hover{
    color: #D16500!important;
    font-weight: bold !important;
}
.neuralearn-logo{
    font-family: 'Comic Neue', cursive;
    color: #fff;
}

a.nav-link{
    color: #fff !important;
    text-decoration: none;
    padding: 0px 20px !important;
}
.btn-sm{
    background: #fff;
    color:black;
    outline: none;
    border: none;
}
.mobile-menu{
    color:white;
}
.toggle-menu-btn{
  width: 35px;
    height: 35px;
    border-radius: 50%;
    color:white;
}
.app__navbar-menu {
    // width: 35px;
    // height: 35px;
    // border-radius: 50%;
    // position: relative;
  
    // display: flex;
    // justify-content: center;
    // align-items: center;
    // background-color: white;
  
    svg {
      width: 70%;
      height: 70%;
      color: white;
      cursor: pointer;
    }
  
    div {
      position: fixed;
      top: 0;
      bottom: 0;
      right: 0;
      z-index: 5;
  
      padding: 1rem;
  
      width: 80%;
      height: 100vh;
  
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      flex-direction: column;
  
     
      background-color: white;
      background-size: cover;
      background-repeat: repeat;
  
      /* top box shadow */
      box-shadow: 0px 0px 20px rgba(168, 168, 168, 0.15);
  
      svg {
        width: 35px;
        height: 35px;
        color: grey;
        margin: 0.5rem 1rem;
        cursor: pointer;
      }
  
      ul {
        list-style: none;
        margin: 0;
        padding: 0;
        height: 100%;
        width: 100%;
  
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
  
        li {
          margin: 1rem;
  
          a {
            color: grey;
            text-decoration: none;
            font-size: 1rem;
            text-transform: uppercase;
            font-weight: 500;
  
            transition: all 0.3s ease-in-out;
  
            &:hover {
              color: #D16500;
            }
          }
          li {
            color: #D16500;
            text-decoration: none;
            font-size: 1rem;
            cursor: pointer;
            text-transform: uppercase;
            font-weight: 500;
  
            transition: all 0.3s ease-in-out;
  
            &:hover {
              color: grey;
            }
          }
        }
      }
  
      @media screen and (min-width: 900px) {
        display: none;
      }
    }
  
    @media screen and (min-width: 900px) {
      display: none;
    }
  }
  @media screen and (min-width:900px) {
    .mobile-menu{
      display: none;
    }
  }
  @media screen and (max-width: 900px) {
    .navbar-brand{
      
      width: 100px;
      left: 10px !important;
    }
    .navbar-brand2{
      // position: fixed;
      width: 100px;
      left: 10px !important;
    }
    .navbar-brand img{
      
      width: 20px;
    }
    .neuralearn-logo{
      font-size: .8rem;
    }
    .navpadding{
      padding: 0px 1rem;
  }
  .navbar-container{
    display: flex !important;
    justify-content: space-between;
  }
  
  }
  .nav-ul{
    display: flex;
    max-width: 200px;
    justify-content: flex-end !important;
    
  }
