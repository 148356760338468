.parent {
  background: black;
  margin: 0;
  padding: 0;
}
.card_slide {
  color: white;
  background: #202020 !important;
  text-align: center;
  padding: 10px;
  border-radius: 10px;
}
.card__title {
  font-size: 1.3rem;
  padding: 20px 0px;
  font-weight: bold;
}
.card__content {
  font-size: 0.9rem;
  font-weight: normal;
}

@media screen and (max-width: 1900px) {
  .card__content {
    font-size: 1rem;
  }
  .card__title {
    font-size: 1.3rem;
  }
  .slider .card_slide {
    width: 100%;
    height: 330px;
  }
}
@media screen and (max-width: 1350px) {
  .card__content {
    font-size: 0.9rem;
  }
  .card__title {
    font-size: 1.2rem;
  }
  .slider .card_slide {
    width: 100%;
    height: 390px;
    border-radius: 10px;
  }
}
@media screen and (max-width: 1100px) {
  .card__content {
    font-size: 0.8rem;
  }
  .card__title {
    font-size: 1rem;
  }
}
@media screen and (max-width: 450px) {
  .card__content {
    font-size: 0.8rem;
  }
  .card__title {
    font-size: 1rem;
  }
  .slider .card_slide {
    width: 80%;
    height: 250px;
    margin: 0px auto;
    border-radius: 10px;
  }
}
