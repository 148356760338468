.learningPathbody {
	margin: 0;
	padding: 0;
	background-color: rgb(0, 0, 0);
	color: white;
}

.learning-path-html {
	--orange-primary: rgb(255, 115, 0);

	--grey-blue: rgb(8, 33, 49);

	--white-grey: #555;
	--white-darker-grey: #222;

	& > .title {
		text-align: center;
		font-size: 2.5em;
	}
	.path {
		width: 50%;
		margin: 0 auto;
		font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
			sans-serif;
		article {
			display: flex;
			.left {
				.top {
					display: flex;
					align-items: center;

					.chapter-box {
						position: relative;
						flex-shrink: 0;
						width: 21em;
						min-height: 5em;
						border-radius: 10px;
						background-color: var(--grey-blue);
						display: flex;
						flex-direction: column;
						justify-content: flex-end;
						&::before {
							content: "";
							position: absolute;
							top: -2px;
							background-color: var(--orange-primary);
							width: 100%;
							height: 100%;
							z-index: -1;
							border-radius: 10px;
						}
						.chapter-title {
							margin: 1em 20px 1em 70px;
							padding: 0;
							font-family: "Segoe UI", Tahoma, Geneva, Verdana,
								sans-serif;
						}
					}
					.line {
						position: relative;
						width: 7em;
						width: 100%;
						border-top: 1px dashed var(--white-grey);
						height: 0;
						display: flex;
						align-items: center;
						&::before,
						&::after {
							content: "";
							position: absolute;
							width: 8px;
							height: 8px;
							background-color: white;
							transform: rotate(45deg);
						}
						&::before {
							left: -3px;
						}
						&::after {
							right: -3px;
						}
					}
				}

				.image-block {
					position: relative;
					border-left: 1px dashed var(--orange-primary);
					min-height: 10em;
					margin-left: 2em;
					height: 100%;
					img {
						width: 350px;
						height: 200px;
						object-fit: cover;
						margin: 2.5em 1.5em;
						border-radius: 1em;
						border: 1px solid #222;
					}
					&::before {
						content: "";
						position: absolute;
						width: 10px;
						height: 10px;
						border-radius: 5px;
						background-color: var(--orange-primary);
						transform: translate(-50%, -50%);
						z-index: -1;
					}
				}
			}

			.right {
				display: flex;
				margin-bottom: 3em;
				.wall {
					flex-shrink: 0;
					min-height: calc(5em + 200px + 7em);
					height: 100%;
					width: 3em;
					border: 1px dashed var(--white-grey);
					background-color: var(--white-darker-grey);
				}
				.lessons {
					padding-left: 3px;
				}
				.lesson {
					position: relative;
					display: inline-block;
					background-color: var(--white-darker-grey);
					border-radius: 10px;
					padding: 1.5em 1em;
					margin: 0.5em 0 0.5em 1px;
					transition: background-color 0.3s, box-shadow 0.3s;
					p {
						margin: 0;
						font-size: 14px;
						display: flex;
						align-items: center;
						user-select: none;
						&::before {
							content: "";
							position: absolute;
							width: 8px;
							height: 8px;
							background-color: white;
							transform: rotate(45deg);
							left: -3px;
						}
					}
					&:hover {
						background-color: var(--orange-primary);
						box-shadow: 0 0 5px var(--orange-primary);
					}
				}
			}
		}
	}

	@media screen and (max-width: 1024px) {
		.left {
			.chapter-box {
				width: 17em;
				min-height: 4em;
				border-radius: 7px;

				.chapter-title {
					margin: 1em 20px 1em 50px;
					padding: 0;
					font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
				}
			}
			img {
				width: 300px;
				height: 160px;
			}
		}
	}
	@media screen and (max-width: 768px) {
		.left {
			.chapter-box {
				width: 14em;
				min-height: 3.5em;
				border-radius: 7px;

				.chapter-title {
					margin: 1em 15px 1em 40px;
					padding: 0;
					font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
				}
			}
			.image-block {
				margin-left: 1em;
				img {
					width: 250px;
					height: 120px;
					margin: 1em 1em;
				}
			}
		}
		.right {
			.wall {
				width: 2em;
			}
			.lesson {
				padding: 1.2em 0.8em;
				margin: 0.2em 0 0.2em 1px;
			}
		}
	}
	@media screen and (max-width: 570px) {
		.left {
			.chapter-box {
				width: 10em;
				min-height: 3em;
				border-radius: 7px;
				.chapter-title {
					margin: 1em 15px 1em 20px;
					padding: 0;
					font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
				}
			}
			.image-block {
				margin-left: 0.7em;
				img {
					width: 180px;
					height: 100px;
					margin: 0.5;
				}
			}
		}
		.right {
			.wall {
				width: 1em;
			}
			.lesson {
				padding: 0.7em 1em;
				margin: 0.2em 0 0.2em 1px;
			}
		}
	}
}