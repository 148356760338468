@import url("https://fonts.googleapis.com/css2?family=Encode+Sans+Condensed:wght@700&family=Epilogue:wght@300;500;700&family=Imbue:opsz,wght@10..100,200;10..100,400&family=Rubik+Distressed&display=swap");

.welcome-course {
  text-align: center;
  padding-top: 10px;
  line-height: 10px;
  font-size: 1.4rem;
}
.master-deep-course {
  font-size: 3rem;
  display: flex;
  color: #d16500;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}
.master-deep-course .master {
  color: black;
}
.course-img {
  max-width: 600px;
  margin: 10px auto;
}
.course-img img {
  width: 70%;
  display: block;
  margin: 5px auto;
}
.learning-now-container {
  max-width: 180px;
  margin: 0px auto;
}
.learning-now-container p {
  font-weight: bold;
  font-size: 0.8rem;
}
.learning-now-btn a {
  background: #000000;
  text-decoration: none;
  color: white;
  padding: 15px 10px;
  border-radius: 10px;
}
.learning-now-btn a:hover {
  background: #d16500;
  transition: 1s ease-out;
}
.courses-details-container {
  max-width: 1300px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 30px auto;
}
.courses-details-container2 {
  max-width: 1200px;
  display: flex;

  font-family: "Imbue", serif;
  margin: 20px auto;

  flex-wrap: wrap;
  padding-bottom: 40px;
  /* margin: 30px auto; */
}
.courses-details-container1 {
  margin: 10px 10px;
}
.courses-details-container div img {
  margin-right: 10px;
}
.courses-details2 div img {
  margin-right: 10px;
}
.courses-details-container .links1 {
  color: #834f02;
}
.courses-details-container h1 {
  margin: 10px 0px;
}
.courses-details-container a {
  color: #834f02;
  text-decoration: none;
}
.courses-details2 {
  max-width: 650px;
}

.courses-details-container2 h1 {
  color: #865204;
  font-family: "Encode Sans Condensed", sans-serif;
  font-weight: bold;
  margin: 20px;
}
.learning-path-img-container {
  margin-top: 4rem;
  padding-bottom: 5rem !important;
  background: #000000;
}
.learng-path-avatar {
  max-width: 1200px;
  margin: 0px auto;
}
.learng-path-avatar img {
  width: 100%;
  margin: 20px auto 0px auto;
}
.course-part2 {
  margin-top: 4rem;
  margin-left: 10rem;
}

@media screen and (max-width: 900px) {
  .master-deep-course {
    font-size: 1rem;
    margin-left: 5px;
    /* text-align: center; */
    /* te */
  }
  /* .mobile-design{
        text-align:  center !important;
    } */
  .courses-details-container1 {
    padding: 10px;
    font-size: 0.8rem;
  }
  .destop-video {
    display: none;
  }
  .course-details-mobile {
    max-width: 450px;
    margin: 0 auto;
  }
  .courses-details2 div {
    padding: 10px;
  }
  .courses-details-container2 {
    margin: 10px auto;
    font-size: 0.9rem;

    /* margin: 30px auto; */
  }
  .course-part2 {
    margin-top: 0rem;
    margin-left: 10px;
  }
}
@media screen and (min-width: 900px) {
  .course-details-mobile {
    display: none;
  }
}
