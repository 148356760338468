.m-carosel-image{
    max-width: 500px;
    height: 250px;
}
.m-carosel-image img{
    width: 100%;
    height: 100%;
}
.mobile-carosel{
    padding: 10px;
    color: #fff;
    background: #00042A;
}
