.homeHeadContainer{
    background: rgba(241, 241, 241, 0.39);
    margin: 0;
}
.homehead{
    margin: 0 auto;
    max-width: 1200px;
    
    padding-top: 1rem;
    text-align: center;
}
.welcome{
    text-align: center;
    padding:30px 0;
    line-height: 10px;
    
    font-size: 1.4rem;
}


.gainContainer{

    width: 40%;
    margin: 10px auto;
    font-size: .8rem;
    text-align: center;
}
.gainContainer span{
    color:#D16500 ;
}
.master-deep{
    font-size: 4rem;
    display: flex;
    color: #D16500;
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
    line-height: 5rem;
    justify-content: center;
    align-items: center;
    font-weight: bold;
}
.master-deep .master{
    color: black;
}
.start{
    
    text-align: left;
}
.skills{
    font-family: 'Encode Sans Condensed', sans-serif;
    font-size: 1.2rem;
}
.arrow1{
    margin-top: 5px;

    width: 8px;
    height: 15px;
}

.bounce-container{
    display: flex;
}
.downArrow img{
    margin: 0px 5px;
}
.bounce {-moz-animation: bounce 1s infinite;-webkit-animation: bounce 1s infinite;animation: bounce 1s infinite;}
@-moz-keyframes bounce {
    0%, 20%, 50%, 80%, 100% {-moz-transform: translateY(0);-ms-transform: translateY(0);-webkit-transform: translateY(0);transform: translateY(0); }
    40% {-moz-transform: translateY(-5px); -ms-transform: translateY(-5px);-webkit-transform: translateY(-5px);transform: translateY(-5px);}
    60% {-moz-transform: translateY(-10px);-ms-transform: translateY(-10px); -webkit-transform: translateY(-10px); transform: translateY(-10px); }
}
@-webkit-keyframes bounce {
    0%, 20%, 50%, 80%, 100% {-moz-transform: translateY(0);-ms-transform: translateY(0);-webkit-transform: translateY(0);transform: translateY(0); }
    40% {-moz-transform: translateY(-5px); -ms-transform: translateY(-5px);-webkit-transform: translateY(-5px);transform: translateY(-5px);}
    60% {-moz-transform: translateY(-10px);-ms-transform: translateY(-10px); -webkit-transform: translateY(-10px); transform: translateY(-10px); }
}
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {-moz-transform: translateY(0);-ms-transform: translateY(0);-webkit-transform: translateY(0);transform: translateY(0); }
  40% {-moz-transform: translateY(-5px); -ms-transform: translateY(-5px);-webkit-transform: translateY(-5px);transform: translateY(-5px);}
  60% {-moz-transform: translateY(-10px);-ms-transform: translateY(-10px); -webkit-transform: translateY(-10px); transform: translateY(-10px); }
}
@media screen and (max-width:900px) {
    /* .master-deep{
        display: none !important;
    } */
    .master-deep{
        font-size: 1.5rem;
        color: #D16500;
        line-height: 2rem;
    }
    .welcome{
       
        padding:10px 0;
        line-height: 10px;
        font-size: 1.2rem;
    }
    .gainContainer{

        width: 100%;
        padding: 10px;
        font-size: .7rem;
        text-align: center;
    }
    .gainContainer span{
        color:#D16500 ;
    }
    .start{
        margin: 10px;
        text-align: left;
    }
    
}