
.buit-text2{
    font-size: 1.4rem;
    font-weight: bold;
}
.ct1{
    display: block;
    /* margin: 0 auto; */
    width: 95%;
    height: 100%;
    border: 2px solid #808295;
    /* padding:0 5px; */
    border-radius: 35px;
}
.ct2{
    display: block;
    width: 97%;
    height: 90%;
    border: 2px solid #808295;
    margin-right: 50px;
    border-radius: 35px;
}
.ct3{
    display: block;
   
    /* margin: 15px auto; */
    margin-top: 15px;
    margin-right: 70px;
    width: 75%;
    border: 2px solid #808295;
    /* padding: 5px; */
    border-radius: 35px;
}
.ct4{
    width: 90%;
    height: 70%;
    border: 2px solid #808295;
    margin-top: 80px;
    margin-right: 30px;
    padding: 5px;
    border-radius: 35px;
}
.bubbles2{
    margin-bottom: 1rem;
    display: flex;
    width: 90%;
    justify-content: flex-end;
}