.cf1{
    display: block;
    width: 95%;
    height: 90%;
    border: 2px solid #808295;
    margin-right: 50px;
    border-radius: 35px;
}
.cf2{
    display: block;
    width: 98%;
    height: 90%;
    margin-left: 10px;
    border: 2px solid #808295;
    margin-top: 80px;
    border-radius: 35px;
}
.cf3{
    display: block;
    margin-top: 20px ;
    margin-right: 20px;
    width: 97%;
    border: 2px solid #808295;
    /* padding: 5px; */
    border-radius: 35px;
}
.cf4{
    width: 90%;
    height: 80%;
    margin-top: 80px;
    border: 2px solid #808295;
    margin-bottom: 15px;
    margin-left: 10px;
    padding: 5px;
    border-radius: 35px;
}
.bubbles3{
    margin-bottom: 1rem;
    display: flex;
    width: 90%;
    justify-content: flex-end;
    
}
.buit-text3{
    font-size: 1.4rem;
    margin-bottom: 20px;
    font-weight: bold;
}