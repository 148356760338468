@import url('https://fonts.googleapis.com/css2?family=Encode+Sans+Condensed:wght@700&family=Epilogue:wght@300;500;700&display=swap');
.carosel-box , 
.carosel-box-mobile
{
    width: 100%;
    padding: 10px 0px;
    background: #00042A;
    
}

.builtContainer{
    background: #00042A;
    color: #fff;
    margin: 0;
    padding: 20px 0px;
}
.arrow{
    border: 1px solid red !important;
    display: none !important;
}
.spanBox{
    margin: 0px auto !important;
}
.carosel-button1{
    background: #FF9900 !important;

}
.carosel-button2{
    background: #150099 !important;
}
.carosel-button3{
    background: #586E00 !important;
}
.carosel-button4{
    background: #FB8181 !important;
}
.built{
    display: flex;
    justify-content: space-evenly;
}
.buit-text{
    font-size: 1.8rem;
    font-weight: bold;
}
.main-built{
    max-width: 1200px;
    margin: 0px auto;
    display: flex;
    justify-content: center;
}

.image1{
    display: block;
    /* margin: 0 auto; */
    width: 95%;
    border: 2px solid #808295;
    /* padding:0 5px; */
    border-radius: 35px;
}
.midlleImages{
    display: flex;
    /* height: 380px; */
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}
.buit-text{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.image3Container{
    display: flex;
    width: 100%;
    justify-content: flex-end;
}
.image2{
    display: block;
    width: 80%;
    border: 2px solid #808295;
    margin-right: 50px;
    border-radius: 35px;
}
.image3{
    display: block;
    margin-top: 20px ;
    margin-right: 20px;
    width: 70%;
    border: 2px solid #808295;
    /* padding: 5px; */
    border-radius: 35px;
}
.image4-container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.image4{
    width: 90%;
    border: 2px solid #808295;
    padding: 5px;
    border-radius: 35px;
}
.bubbles{
    margin-bottom: 1rem;
    display: flex;
    width: 270px;
    justify-content: flex-end;
    
}
.buble1{
   
    width: 20px;
    height: 20px;
    margin: 0px 3px;
    border-radius: 50%;
    background: transparent;
}
.buble2{
    margin: 0px 3px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: transparent;
}
.buble3{
    margin: 0px 3px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: transparent;
}
.buble4{
    margin: 0px 3px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: transparent;
}
.premium-header{
    margin-top: 30px;
    font-size: 2.5rem;
    margin-left: 3rem;
    font-family: 'Encode Sans Condensed', sans-serif;
    font-weight: 700;
}
.premium{
    color: #8B5400;
}
.pre-container{
   background:  rgba(230, 230, 230, 0.27);
   border-radius: 15px;
   padding: 4rem 3rem;
   
   
}
.deepImgContainer{
    display: flex;
    justify-content: flex-start;
}
.deepImgContainer img{
    width: 100%;
}
.deepImgContainer2 img{
    width: 100%;
    padding: 20px;
}
.price{
    color: #D16500;
    font-size: 2rem;
    font-family: 'Comic Neue', cursive;
    font-weight: bold;
}
.price span{
    color: #D16500;
    /* margin-top: 5rem; */
}
.startContainer{
    display: flex;
    justify-content: center;
    align-items: center;
}
.startContainer a:hover{
    color: #D16500;
    transition: 1s;
}
.startBtn{
    padding:5px 15px ;
    background: #000;
    color: #fff !important;
    text-decoration: none !important;
    font-weight: bold;
    border-radius: 25px;
    margin: 0px 10px;
    cursor: pointer;

}
.startBtn:hover{
    background: #D16500;
    transition: 1s;
}
.startBtn1{
    padding:5px 15px ;
    background: #fff;
    color: #000;
    text-decoration: none;
    font-weight: bold;
    border-radius: 25px;
    cursor: pointer;
    margin: 0px 10px;

}
.startBtn1:hover{
    background: #D16500;
    color: #fff;
    transition: 1s;
}
.startContainer a {
    color: #000;
}
.learnMore{
    color: grey !important;
}
.learnMore:hover{
    color: white !important;
    transition: 1s;
}
.card{
    background: #000000;
    color: #fff;
    width: 80%;
    margin: 0px auto;
    border-radius: 20px !important;
}
.cardImg{
    display: flex;
    align-items: center;
    margin: 30px 0px;
    justify-content: center;
}
.cardImg img{
    width: 150px;
    height: 200px;
}
.objectContainer{
    background: rgba(230, 230, 230, 0.27);
    border-radius: 15px;
    padding: 30px ;
    
}
.card.card1{
    background: inherit;
    border: none;
    margin: 60px 20px;

    color: #000;
    
}
@media screen and (min-width:900px) {
    .carosel-mobile{
        display: none;
    }
}
@media screen and (max-width:900px) {
.carosel-desktop{
    display: none
}

    .deeplearningH1-mobile{
        font-size: 1.3rem;
        text-align: center;
    }
    .pre-container{
        background:  rgba(230, 230, 230, 0.27);
        border-radius: 15px;
        padding: 4rem 3rem;
        
        
     }
     .deepImgContainer{
        display: flex;
        justify-content: center;
    }
    .col-10 .col-md-6{
        margin: 0 auto !important;
    }
    
}
