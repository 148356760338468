
.footer-top{
    background: #000000;
    padding: 40px;
    /* margin-top: 20px; */
    max-width:100% ;
}
.f-heading{
    text-align: center;
    padding-top:30px ;
    color: white;
    font-weight: bold;
    font-size: 3rem;
}
.f-details{
    max-width: 400px;
    font-size: 1.2rem;
    color: white;
    text-align: center;
    margin: 20px auto;

}
.f-input{
    max-width: 350px;
    margin: 0px auto;
    /* display: flex;
    justify-content: center; */
}
label span{
    display: block;
    padding-bottom: 10px;
    font-weight: bold;
    
    color: #A7560C;
}
.f-input {
    padding: 10px 30px;
}
.f-input input{
    background: #232323;
    outline: none;
    color: white;
    width: 300px;
    border-radius: 10px;
    padding: 10px;
}
.join-container{
    display: flex;
    justify-content: center;
    align-items: center;
}
.join-btn{
    width: 100px;
    border-radius: 15px;
    color: #000000;
    font-weight: bold;
    padding:  5px;
    margin: 5px auto;
}
.f-container{
    background:#E6E6E6 ;
}
.f-bootom-container{

    max-width: 1300px;
    margin: 0px auto;
    
}
.footer-bottom>div{
 margin: 40px 30px;
 
}
.course1{
 
 margin-left: 70px !important;
 
}
.footer-bottom{
    
  flex-wrap: wrap;
   display: flex;
   justify-content: flex-start;
   
}
.f-logo-container{
    padding-top: 10px;
    max-width: 1100px;
}
.f-logo{
    padding: 10px;
    border-bottom: 1px solid grey;
    width: 200px;
    display: block;
}


.courses a{
    text-decoration: none;
    font-size: .9rem;
    color: grey;
    margin: 5px 0;
    display: block;
}
.courses>h3{
    margin: 20px 0;
    font-size: 1.6rem;
}
.f-logo span{
    color: black;
    font-weight: bold;
}
.f-copyright{
    margin: 0;
  
}
.copyright p{
    margin: 10px auto;
}
.copyright{
    width: fit-content;
    padding: 8px 100px;
    text-align: center;
    background: #000000;
    border-radius: 15px 15px 0px 0px;
    color: white;
    margin: 0px auto;
}
@media screen and (max-width:900px) {
    .f-logo{
        padding: 10px;
        border-bottom: 1px solid grey;
        width: 300px;
        display: block;
    }
    .footer-bottom>div{
        margin: 10px 30px;
        
       }
    .course1{
 
        margin-left: 30px  !important;
        
    }
    .f-input{
        max-width: 250px;
        margin: 0px auto;
       
    }
    .f-input input{
        font-size: .8rem;
        width: 200px;

    }
    .copyright{
        width: 100%;
        padding: 10px ;

    }
       .copyright {
        margin: 0px auto;
      
        font-size: .7rem;
    }
}