/* Scrollbar Styling */
body {
  box-sizing: border-box;
  margin: 0;
  overflow-x: hidden;
  padding: 0;
}
::-webkit-scrollbar {
  width: 6px;
  border-radius: 2px;
}
::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: black;
  border-radius: 10px;
  border: solid #6d6d6d 10px;
}
::-webkit-scrollbar-track {
  /* margin-top: 45px; */
  background-color: #ebebeb;
  border-radius: 10px;
  width: 6px;
}

body {
  margin: 0;

  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.notFound {
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}
.nf-btn {
  background: #d16500;
  margin-top: 20px;
  color: white;
  font-weight: bold;
}
.nf-btn:hover {
  transition: 0.5s ease-in-out;
}
.learning-path-img-container {
  display: none;
}
.learning-path-html {
  display: block;
}

@media screen and (max-width: 670px) {
  .learning-path-img-container {
    display: block;
  }
  .learning-path-html {
    display: none;
  }
}
.oldPrice {
  text-decoration: line-through;
  font-size: 1.2rem;
  padding-right: 10px;
}
