.discount-container {
  position: fixed;
  z-index: 9999;
  top: 0;
  transition: 0.3s ease-in;
  background: black;
  width: 100vw;
  color: white;
  text-align: center;
  padding: 15px 0px;
  font-size: 1.2rem;
}
.discount-container-hide {
  position: absolute;
  z-index: 0;
  opacity: 0;
  /* top: -100 !important; */
  transition: 0.3s ease-in-out;
  background: black;
  width: 100vw;
  color: white;
  text-align: center;
  padding: 10px 0px;
  font-size: 1.1rem;
}
