.c2{
    display: block;
    width: 95%;
    height: 90%;
    border: 2px solid #808295;
    margin-right: 50px;
    border-radius: 35px;
}
.c3{
    display: block;
    margin-top: 20px ;
    margin-right: 60px;
    width: 70%;
    border: 2px solid #808295;
    /* padding: 5px; */
    border-radius: 35px;
}
.c4{
    width: 90%;
    height: 70%;
    border: 2px solid #808295;
    margin-top: 80px;
    /* margin-left: 10px; */
    padding: 5px;
    border-radius: 35px;
}